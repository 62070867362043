import { getProducts, settingEnable } from '@/api/product';
import { ElMessage } from 'element-plus';
import { getProductCategoryList } from '@/api/common';
export default {
  namespaced: true,
  state: {
    product_list: [],
    pageSize: 10,
  },

  getters: {
    getProductList: (state) => state.product_list,
    getPageSize: (state) => state.pageSize,
  },

  mutations: {
    SET_ALL_PRODUCTS(state, data) {
      state.product_list = data;
    },
    UPDATE_PRODUCT(state, item) {
      const index = state.product_list.findIndex((el) => el.alias_code === item.alias_code);
      state.product_list.splice(index, 1, item);
    },
    RESET(state) {
      state.product_list = [];
    },
  },

  actions: {
    async doGetProducts({ commit }) {
      const res = await getProducts();
      commit('SET_ALL_PRODUCTS', res.data);
      return Promise.resolve();
    },
    async doSetEnable({ commit }, data) {
      data.is_enable = !data.is_enable;
      await settingEnable(data);
      ElMessage.success(`${data.name}-${data.is_enable ? '上架' : '下架'}成功`);
      commit('UPDATE_PRODUCT', data);
      return Promise.resolve();
    },
    //取得商品類別列表
    async doGetProductCategory({ commit, rootGetters }) {
      if (!rootGetters['common/getCategoryList']) {
        const cate = await getProductCategoryList();
        commit('common/SET_CATEGORY_LIST', cate.data, { root: true });
      }
      return Promise.resolve();
    },
  },
};
