import { analysisList, analysisCSVFile } from '@/api/order';
import router from '@/router';

export default {
  namespaced: true,
  state: {
    search_condition: null,
    current_tab: '1',
    current_status: '', // 是排行榜or趨勢圖
    current_page: 1,
    current_category: ['all'], // 當頁類別(for:下拉)
    page_size: 10,
    total_page: 1,
    data_length: 1, // 所有資料筆數
    data_all: [], // 所有資料
    data_page: [], // 當頁資料
    search_text: '', // 搜尋文字
  },

  getters: {
    getSearchCondition: (state) => state.search_condition,
    getCurrentTab: (state) => state.current_tab,
    getCurrentStatus: (state) => state.current_status,
    getCurrentPage: (state) => state.current_page,
    getCurrentCategory: (state) => state.current_category,
    getPageSize: (state) => state.page_size,
    getTotalPage: (state) => state.total_page,
    getDataLength: (state) => state.data_length,
    getDataAll: (state) => state.data_all,
    getDataPage: (state) => state.data_page,
    getSearchText: (state) => state.search_text,
  },

  mutations: {
    SET_CTAB(state, data) {
      state.current_tab = data;
    },
    SET_CCATEGORY(state, arr) {
      state.current_category = arr;
    },
    SET_CSTATUS(state, data) {
      state.current_status = data;
    },
    SET_CPAGE(state, data) {
      state.current_page = data;
    },
    SET_TPAGE(state, data) {
      state.total_page = data;
    },
    SET_PAGE_OPTION(state, data) {
      if ('cp' in data == true) state.current_page = data.cp;
      if ('tp' in data == true) state.total_page = data.tp;
      if ('dl' in data == true) state.data_length = data.dl;
      if ('da' in data == true) state.data_all = data.da;
    },
    SET_DATAPAGE(state, data) {
      state.data_page = data;
    },
    SET_SEARCH_TEXT(state, data) {
      state.search_text = data;
    },
    SET_SEARCH_CONDITION(state, data) {
      state.search_condition = data;
    },
    RESET(state) {
      state.search_condition = null;
      state.current_tab = '1';
      state.current_page = 1;
      state.current_category = ['all'];
      state.total_page = 1;
      state.data_length = 0;
      state.data_all = [];
      state.data_page = [];
      state.current_status = '';
      state.search_text = '';
    },
  },

  actions: {
    // 當頁資料
    onePageData({ getters, commit }) {
      let cate = getters.getCurrentCategory[0];
      let returnData;
      let tp = getters.getTotalPage;
      let cp = getters.getCurrentPage;
      let dl = getters.getDataLength;
      let da = getters.getDataAll.filter((data) => data.name == cate)[0]?.data;
      if (getters.getSearchText.trim() != '')
        da = da.filter((data) => data.event_name.toLowerCase().includes(getters.getSearchText.toLowerCase()));
      let size = getters.getPageSize;
      // console.log('cate', cate, 'tp--', tp, 'cp:', cp, 'dl', dl, 'da', da, 'size', size)
      if (tp == 1) returnData = da;
      else if (tp == cp) returnData = da.slice((cp - 1) * size, dl);
      else returnData = da.slice((cp - 1) * size, cp * size);
      if (returnData == undefined) returnData = [];
      // console.log('當頁資料-----', returnData);
      commit('SET_DATAPAGE', returnData);
    },
    // 取得資料
    async getAnalysisData({ getters }) {
      // console.log('當前tab: ', getters.getCurrentTab)
      let payload = {
        type: getters.getCurrentTab,
        // shop_id: ['DYS0467'],
        // start_date: '20230901',
        // end_date: '20231001',
        shop_id: [],
        start_date: getters.getSearchCondition.date[0],
        end_date: getters.getSearchCondition.date[1],
      };
      if (sessionStorage.getItem('is_headquarters') == 'true') {
        let shop_id = getters.getSearchCondition == null ? '' : getters.getSearchCondition.shop.id;
        if (shop_id != '') payload.shop_id = [shop_id];
      }
      let res = await analysisList(payload);
      res = res.data;
      return res;
    },
    // 有下拉的tab
    async getSortData({ getters, dispatch, commit }, sortName) {
      let list = await dispatch('getAnalysisData');
      // console.log('list---', list);
      let listOption = []; // 類別陣列
      let listObj = {}; // 資料依據類別分類放這邊
      list.forEach((element) => {
        if (listOption.indexOf(element.sub_type_description) == -1) listOption.push(element.sub_type_description);
      });
      for (let i = 0; i < list.length; i++) {
        // 原始資料依據類別分類
        for (let j = 0; j < listOption.length; j++) {
          if (list[i].sub_type_description == listOption[j]) {
            if (listObj[`list${j}`] == undefined) listObj[`list${j}`] = [];
            listObj[`list${j}`].push(list[i]);
          }
        }
      }
      for (let i = 0; i < Object.keys(listObj).length; i++) {
        // 排序：sortName 由大到小
        listObj[`list${i}`] = listObj[`list${i}`].sort((a, b) => b[sortName] - a[sortName]);
      }
      for (let i = 0; i < Object.keys(listObj).length; i++) {
        // 排序後，加入排名
        listObj[`list${i}`].forEach((obj, index) => {
          obj.rank = index + 1;
        });
        if (sortName == 'total_amount') {
          // 客源概況-加入客單價(銷售總淨額/訂單筆數)
          listObj[`list${i}`].forEach((obj) => {
            obj.price = obj.total_amount == 0 ? 0 : Math.round(obj.total_amount / obj.total_count);
            obj.data.forEach((item) => {
              item.price = item.amount == 0 ? 0 : Math.round(item.amount / item.count);
            });
          });
        }
      }
      let da = []; // 準備commit已整理好的資料放這
      for (let i = 0; i < listOption.length; i++) {
        da.push({
          name: listOption[i],
          data: listObj[`list${i}`],
        });
      }
      let firstDatalength = list.length == 0 ? 0 : listObj.list0.length;
      commit('SET_PAGE_OPTION', {
        cp: getters.getCurrentPage,
        tp: firstDatalength <= getters.getPageSize ? 1 : Math.ceil(firstDatalength / getters.getPageSize),
        dl: firstDatalength,
        da: da,
      });
      commit('SET_CCATEGORY', [listOption[router.currentRoute.value.query.cate - 1]]);

      dispatch('onePageData');
      return Promise.resolve();
    },
    // 門市銷售資料
    async storeAnalysis({ getters, dispatch, commit }) {
      commit('SET_CCATEGORY', ['all']);
      let list = await dispatch('getAnalysisData');
      list = list.sort((a, b) => b.total_amount - a.total_amount); // 排序：total_amount由大到小
      list.forEach((obj, index) => {
        // 加入排名、客單價(銷售總淨額/訂單筆數)
        obj.rank = index + 1;
        obj.price = obj.total_amount == 0 ? 0 : Math.round(obj.total_amount / obj.total_count);
        obj.data.forEach((item) => {
          // 客單價(銷售總淨額/訂單筆數)
          item.price = item.amount == 0 ? 0 : Math.round(item.amount / item.count);
        });
      });
      commit('SET_PAGE_OPTION', {
        cp: 1,
        tp: list.length <= getters.getPageSize ? 1 : Math.ceil(list.length / getters.getPageSize),
        dl: list.length,
        da: [
          {
            name: 'all',
            data: list,
          },
        ],
      });
      dispatch('onePageData');
      return Promise.resolve();
    },
    // 商品銷售資料
    async productAnalysis({ dispatch }) {
      await dispatch('getSortData', 'total_count');
      return Promise.resolve();
    },
    // 支付方式資料
    async paywayAnalysis({ getters, dispatch, commit }) {
      commit('SET_CCATEGORY', ['all']);
      let list = await dispatch('getAnalysisData');
      // console.log('paywayAnalysis----', list);
      list = list.sort((a, b) => b.total_count - a.total_count); // 排序：total_count由大到小
      list.forEach((obj, index) => {
        // 加入排名
        obj.rank = index + 1;
      });
      commit('SET_PAGE_OPTION', {
        cp: 1,
        tp: list.length <= getters.getPageSize ? 1 : Math.ceil(list.length / getters.getPageSize),
        dl: list.length,
        da: [
          {
            name: 'all',
            data: list,
          },
        ],
      });
      dispatch('onePageData');
      return Promise.resolve();
    },
    // 折扣概況資料
    async discountAnalysis({ dispatch }) {
      await dispatch('getSortData', 'total_count');
      return Promise.resolve();
    },
    // 客源概況資料
    async customerAnalysis({ dispatch }) {
      await dispatch('getSortData', 'total_amount');
      return Promise.resolve();
    },
    //取得分析CSV
    async doGetAnalysisCSVFile({ getters }) {
      let payload = {
        type: getters.getCurrentTab,
        shop_id: [],
        start_date: getters.getSearchCondition.date[0],
        end_date: getters.getSearchCondition.date[1],
      };
      if (sessionStorage.getItem('is_headquarters') == 'true') {
        let shop_id = getters.getSearchCondition == null ? '' : getters.getSearchCondition.shop.id;
        if (shop_id != '') payload.shop_id = [shop_id];
      }
      let res = await analysisCSVFile(payload);
      return Promise.resolve(res);
    },
  },
};
