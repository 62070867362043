import { reasonList, saveReasonList } from '@/api/systemPreferences';
export default {
  namespaced: true,
  state: {
    reason_list: {},
  },

  getters: {
    getReasonList: (state) => state.reason_list,
  },

  mutations: {
    SET_REASON_LIST(state, data) {
      state.reason_list = data;
    },
    RESET(state) {
      state.reason_list = [];
    },
  },
  actions: {
    //取得原因列表
    async doGetReasonList({ commit }) {
      const list = await reasonList();
      // console.log('doGetReasonList---', list)
      commit('SET_REASON_LIST', list);
      return Promise.resolve();
    },
    //儲存原因列表
    async doSaveReasonList(context, data) {
      await saveReasonList(data);
      // const save = await saveReasonList(data);
      // console.log('save---', save.message);
      return Promise.resolve();
    },
  },
};
