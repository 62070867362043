import request from '@/utils/request';
const baseURL = '/api/backend/';

//取得各門市商品列表
export function getProducts() {
  return request({
    method: 'get',
    url: `${baseURL}product/list`,
  });
}

//商品上下架
export function settingEnable(data) {
  return request({
    method: 'post',
    url: `${baseURL}product/settingEnable`,
    data,
  });
}

//取得總公司商品列表
export function getAllProduct(data) {
  return request({
    method: 'post',
    url: `${baseURL}product/option/list`,
    data,
  });
}

//取得總公司商品總覽
export function allProductSummary(data) {
  return request({
    method: 'post',
    url: `${baseURL}product/option/summary`,
    data,
  });
}

//取得總公司商品詳細規格
export function getAllProductDetail(product_id) {
  return request({
    method: 'get',
    url: `${baseURL}product/option/detail?product_id=${product_id}`,
  });
}

//儲存總公司商品詳細規格
export function saveProductDefault(data) {
  return request({
    method: 'post',
    url: `${baseURL}product/option/save`,
    data,
  });
}

//更新商品
export function updateProduct() {
  return request({
    method: 'post',
    url: `${baseURL}product/data/update`,
  });
}

//更新商品狀態
export function updateProductInfo() {
  return request({
    method: 'get',
    url: `${baseURL}product/data/info`,
  });
}
