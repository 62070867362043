import {
  getAllProduct,
  allProductSummary,
  getAllProductDetail,
  saveProductDefault,
  updateProduct,
  updateProductInfo,
} from '@/api/product';
import { getProductCategoryList } from '@/api/common';
export default {
  namespaced: true,
  state: {
    search_text: '',
    category_id: '',
    product_list: [],
    pageSize: 10,
    pageTotal: 0,
    currentOptions: null,
    currentDefault: null,
    currentProduct: null,
    is_detail: false,
    is_change: false,
    product_message: '',
    product_status: null,
  },

  getters: {
    getSearchText: (state) => state.search_text,
    getCategoryId: (state) => state.category_id,
    getProductList: (state) => state.product_list,
    getPageSize: (state) => state.pageSize,
    getPageTotal: (state) => state.pageTotal,
    getCurrentOptions: (state) => state.currentOptions,
    getCurrentDefault: (state) => state.currentDefault,
    getCurrentName: (state) => state.currentProduct.product_name,
    getIsDetail: (state) => state.is_detail,
    getDefaultIsChange: (state) => state.is_change,
    getProductMessage: (state) => state.product_message,
    getProductStatus: (state) => state.product_status,
  },

  mutations: {
    SET_SEARCH(state, data) {
      state.search_text = data;
    },
    SET_CATEGORY_ID(state, data) {
      state.category_id = data;
    },
    SET_PRODUCT_LIST(state, data) {
      state.product_list = data;
    },
    SET_PAGE_TOTAL(state, data) {
      state.pageTotal = data;
    },
    SET_CURRENT_OPTIONS(state, data) {
      state.currentOptions = data.options;
      state.currentDefault = data.default_value;
      if (data.product) state.currentProduct = data.product;
      state.is_detail = true;
      state.is_change = false;
    },
    CLEAR_CURRENT_OPTIONS(state) {
      state.currentOptions = null;
      state.currentDefault = null;
      state.currentProduct = null;
      state.is_change = false;
    },
    SET_IS_DETAIL(state, data) {
      state.is_detail = data;
    },
    SET_IS_CHANGE(state, data) {
      state.is_change = data;
    },
    SET_PRODUCT_MESSAGE(state, data) {
      state.product_message = data;
    },
    SET_PRODUCT_STATUS(state, data) {
      console.log(data);
      state.product_status = data;
    },
    RESET(state) {
      state.search_text = '';
      state.category_id = '';
      state.product_list = [];
      state.pageTotal = 0;
      state.currentOptions = null;
      state.currentDefault = null;
      state.currentProduct = null;
      state.is_detail = false;
      state.is_change = false;
    },
  },
  actions: {
    //取得商品列表
    async doGetAllProductList({ getters, commit }, data) {
      const payload = { ...data, length: getters.getPageSize };
      const list = await getAllProduct(payload);
      commit('SET_PRODUCT_LIST', list.data);
      return Promise.resolve();
    },
    //取得商品總覽
    async doGetAllProductSummary({ commit }, data) {
      const sum = await allProductSummary(data);
      commit('SET_PAGE_TOTAL', sum.total_count);
      return Promise.resolve();
    },
    //取得總公司商品詳細規格
    async doGetAllProductDetail({ commit }, product) {
      const options = await getAllProductDetail(product.product_id);
      //抓取預設值組合物件
      let default_value = {};
      const findDefault = (type, arr) => {
        let value = type === 'add_on' ? [] : '';
        arr.forEach((e) => {
          if (e.default) {
            if (type === 'add_on') value.push(e.id);
            else value = e.id;
          }
        });
        return value;
      };
      default_value.size = findDefault('size', options.size);
      default_value.temperature = findDefault('temperature', options.temperature);
      default_value.sweetness = findDefault('sweetness', options.sweetness);
      // default_value.add_on = findDefault('add_on', options.add_on);
      commit('SET_CURRENT_OPTIONS', { options, default_value, product });
      return Promise.resolve();
    },
    //儲存
    async doSaveProductDefault({ commit }, data) {
      await saveProductDefault(data.options);
      commit('SET_CURRENT_OPTIONS', { options: data.options, default_value: data.default_value });
      return Promise.resolve();
    },
    //取得商品類別列表
    async doGetProductCategory({ commit, rootGetters }) {
      if (!rootGetters['common/getCategoryList']) {
        const cate = await getProductCategoryList();
        commit('common/SET_CATEGORY_LIST', cate.data, { root: true });
      }
      return Promise.resolve();
    },
    //更新商品
    async doUpdateProduct({ commit }) {
      const message = await updateProduct();
      commit('SET_PRODUCT_MESSAGE', message.data);
      return Promise.resolve();
    },
    //更新商品狀態
    async doUpdateProductInfo({ commit }) {
      const res = await updateProductInfo();
      commit('SET_PRODUCT_STATUS', res);
      return Promise.resolve();
    },
  },
};
