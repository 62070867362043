import { getOptionList, storeList, getAccountPermission, getPriceSet } from '@/api/common';
export default {
  namespaced: true,
  state: {
    options_list: null,
    product_list: null,
    product_tree: null,
    category_list: null,
    store_list: null,
    permission_list: null,
    price_list: null,
  },

  getters: {
    getOptionsList: (state) => state.options_list,
    getProductList: (state) => state.product_list,
    getProductTree: (state) => state.product_tree,
    getCategoryList: (state) => state.category_list,
    getStoreList: (state) => state.store_list,
    getPermissionList: (state) => state.permission_list,
    getPriceSet: (state) => state.price_list,
  },

  mutations: {
    SET_OPTIONS_LIST(state, data) {
      state.options_list = data;
    },
    SET_PRODUCT_LIST(state, data) {
      state.product_list = data;
    },
    SET_PRODUCT_TREE(state, data) {
      state.product_tree = data;
    },
    SET_CATEGORY_LIST(state, data) {
      state.category_list = data;
    },
    SET_STORE_LIST(state, data) {
      const new_list = [];
      data.forEach((area, a_index) => {
        new_list.push({ label: area.name, value: area.name, children: [] });
        area.city.forEach((city, c_index) => {
          new_list[a_index].children.push({ label: city.name, value: city.name, children: [] });
          city.store.forEach((store) => {
            new_list[a_index].children[c_index].children.push({
              label: store.erp_id + ' ' + store.name,
              value: store.shop_id + ' ' + store.erp_id + ' ' + store.name,
              name: store.name,
            });
          });
        });
      });
      state.store_list = new_list;
    },
    SET_PERMISSION_LIST(state, data) {
      state.permission_list = data;
    },
    SET_PRICE_LIST(state, data) {
      state.price_list = data;
    },
    RESET(state) {
      state.options_list = null;
      state.product_list = null;
      state.product_tree = null;
      state.category_list = null;
      state.store_list = null;
      state.permission_list = null;
      state.price_list = null;
    },
  },
  actions: {
    //取得容量選項對照表
    async doGetOptionList({ getters, commit }) {
      if (!getters.getOptionsList) {
        const list = await getOptionList();
        commit('SET_OPTIONS_LIST', list.data);
      }
      return Promise.resolve();
    },
    //取得門市資訊
    async doGetStoreList({ getters, commit }) {
      if (!getters.getStoreList) {
        const stores = await storeList();
        commit('SET_STORE_LIST', stores.data);
      }
      return Promise.resolve();
    },
    //取得權限類別
    async doGetPermissionList({ getters, commit }) {
      if (!getters.getPermissionList) {
        const list = await getAccountPermission();
        commit('SET_PERMISSION_LIST', list.data);
      }
      return Promise.resolve();
    },
    //取得價格模式
    async doGetPriceSet({ getters, commit }) {
      if (!getters.getPriceSet) {
        const list = await getPriceSet();
        commit('SET_PRICE_LIST', list.data);
      }
      return Promise.resolve();
    },
  },
};
