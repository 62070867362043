// import { orderList, orderSummary, orderDetail, queryInfo, encodedNumber, orderRecord } from '@/api/order';
import { getProductCategoryTree, getProductCategoryList } from '@/api/common';
export default {
  namespaced: true,
  state: {
    category_list: [],
    search_condition: null,
    pageSize: 10,
    pageTotal: 0,
    category_detail: null,
    is_detail: false,
  },

  getters: {
    getCategoryList: (state) => state.category_list,
    getSearchCondition: (state) => state.search_condition,
    getPageSize: (state) => state.pageSize,
    getPageTotal: (state) => state.pageTotal,
    getCategoryDetail: (state) => state.category_detail,
    getIsDetail: (state) => state.is_detail,
  },

  mutations: {
    SET_CATEGORY_LIST(state, data) {
      state.category_list = data;
    },
    SET_PAGE_TOTAL(state, data) {
      state.pageTotal = data;
    },
    SET_CATEGORY_DETAIL(state, data) {
      state.category_detail = data;
      state.is_detail = true;
    },
    SET_IS_DETAIL(state, data) {
      state.is_detail = data;
    },
    SET_SEARCH_CONDITION(state, data) {
      state.search_condition = data;
    },
    CLEAR_CATEGORY_DETAIL(state) {
      state.category_detail = null;
    },
    RESET(state) {
      state.category_list = [];
      state.search_condition = null;
      state.pageTotal = 0;
      state.category_detail = null;
      state.is_detail = false;
    },
  },

  actions: {
    // 取得或刷新商品類別列表、商品樹
    async doGetProductInfo({ commit, rootGetters }, action) {
      if (action == 'get') {
        if (!rootGetters['common/getCategoryList']) {
          const cate = await getProductCategoryList();
          commit('common/SET_CATEGORY_LIST', cate.data, { root: true });
        }
        if (!rootGetters['common/getProductTree']) {
          const tree = await getProductCategoryTree();
          commit('common/SET_PRODUCT_TREE', tree.data, { root: true });
        }
      } else {
        const tree = await getProductCategoryTree();
        commit('common/SET_PRODUCT_TREE', tree.data, { root: true });
        const cate = await getProductCategoryList();
        commit('common/SET_CATEGORY_LIST', cate.data, { root: true });
      }
      return Promise.resolve();
    },

    //新增類別
    async doSaveCategory({ dispatch }, data) {
      console.log('doSaveCategory', data);
      // await saveCategory(data);

      await dispatch('doGetProductInfo', 'refresh');
      return Promise.resolve();
    },
    //刪除類別
    async doDeleteCategory({ dispatch }, id) {
      console.log('doDeleteCategory', id);
      // await deleteCategory(id);

      await dispatch('doGetProductInfo', 'refresh');
      return Promise.resolve();
    },
    //取得商品類別列表
    async doGetCategoryList({ commit, dispatch }, data) {
      console.log('doGetCategoryList');
      commit('SET_CATEGORY_LIST', [
        {
          id: 1,
          name: '茶飲',
          tag: '飲品',
          shop: '台北錦西店',
          status: '已上架',
          products: '紅茶、奶茶',
        },
        {
          id: 2,
          name: '鮮乳',
          tag: '飲品',
          shop: '台北錦西店',
          status: '已上架',
          products: '黑糖鮮奶、抹茶拿鐵',
        },
      ]);

      // await categoryList(data);
      // await categorySummary(data); // 取得總筆數
      commit('SET_SEARCH_CONDITION', data.search);

      await dispatch('doGetProductInfo', 'get');
      return Promise.resolve();
    },
    //商品類別詳細
    async doGetCategoryDetail({ commit }, item) {
      console.log('doGetCategoryDetail', item);
      // const res = await categoryDetail(item.id);
      // commit('SET_CATEGORY_DETAIL', { ...res, create_datetime: item.create_datetime });
      commit('SET_CATEGORY_DETAIL', [
        {
          id: 1,
          products_inner: [
            {
              name: '多多綠茶',
              id: 'M1',
            },
            {
              name: '高山青茶',
              id: 'M2',
            },
            {
              name: '中焙鐵觀音',
              id: 'M8',
            },
          ],
          products_outter: [
            {
              name: '紅茶',
              id: 'M3',
            },
            {
              name: '奶茶',
              id: 'M4',
            },
            {
              name: '東方美人茶',
              id: 'M5',
            },
            {
              name: '紅烏龍茶',
              id: 'M6',
            },
            {
              name: '珍珠奶茶',
              id: 'M7',
            },
          ],
        },
      ]);
      return Promise.resolve();
    },
  },
};
