import {
  permissionList,
  permissionSummary,
  permissionTree,
  permissionDelete,
  permissionSave,
  permissionUserList,
} from '@/api/account';
export default {
  namespaced: true,
  state: {
    pageSize: 10,
    pageTotal: 0,
    permissionList: [],
    permissionDetail: null,
    isDetail: false,
    permissionTree: null,
    roleName: null,
    permissionUserList: null,
  },

  getters: {
    getPageSize: (state) => state.pageSize,
    getPageTotal: (state) => state.pageTotal,
    getPermissionList: (state) => state.permissionList,
    getPermissionDateil: (state) => state.permissionDetail,
    getPermissionTree: (state) => state.permissionTree,
    getIsDetail: (state) => state.isDetail,
    getRoleName: (state) => state.roleName,
    getPermissionUserList: (state) => state.permissionUserList,
  },

  mutations: {
    SET_PAGE_TOTAL(state, data) {
      state.pageTotal = data;
    },
    SET_PERMISSION_LIST(state, data) {
      state.permissionList = data;
    },
    SET_PERMISSION_DETAIL(state, data) {
      state.permissionDetail = data;
      state.isDetail = true;
    },
    SET_IS_DETAIL(state, data) {
      state.isDetail = data;
    },
    CLEAR_PERMISSION_DETAIL(state) {
      state.permissionDetail = null;
    },
    SET_PERMISSION_TREE(state, data) {
      state.permissionTree = data;
    },
    SET_ROLE_NAME(state, data) {
      state.roleName = data;
      state.permissionDetail.role_name = data;
    },
    SET_PERMISSION_USER_LIST(state, data) {
      state.permissionUserList = data;
    },
    RESET(state) {
      state.permissionList = [];
      state.permissionDetail = null;
      state.pageTotal = 0;
      state.isDetail = false;
      state.roleName = null;
      state.permissionUserList = null;
    },
  },

  actions: {
    //取得權限角色列表
    async doGetPermissionList({ getters, commit }, data) {
      const payload = { start: data.start, length: getters.getPageSize };
      const list = await permissionList(payload);
      if (data.type === 'init') {
        const sum = await permissionSummary();
        commit('SET_PAGE_TOTAL', sum.total_count);
      }
      commit('SET_PERMISSION_LIST', list.data);
      return Promise.resolve();
    },
    //取得權限列表
    async doGetPermissionTree({ commit }) {
      const list = await permissionTree();
      commit('SET_PERMISSION_TREE', list);
      return Promise.resolve();
    },
    //權限角色新增/編輯
    async doSavePermission(context, data) {
      await permissionSave(data);
      return Promise.resolve();
    },

    //刪除權限角色
    async doDeletePermission(context, id) {
      await permissionDelete(id);
      return Promise.resolve();
    },

    //取得角色帳號列表
    async doGetPermissionUserList({ commit }, id) {
      const list = await permissionUserList(id);
      commit('SET_PERMISSION_USER_LIST', list);
      return Promise.resolve();
    },
  },
};
