import { shopPosList, shopConfigDetail, saveShopConfig, shopDetail, saveShopSetting } from '@/api/shopSystem';
export default {
  namespaced: true,
  state: {
    shop_pos_list: [],
    search_condition: null,
    pageSize: 10,
    pageTotal: 0,
    currentDetail: null,
    is_detail: false,
    is_change: false,
  },

  getters: {
    getShopPosList: (state) => state.shop_pos_list,
    getSearchCondition: (state) => state.search_condition,
    getPageSize: (state) => state.pageSize,
    getPageTotal: (state) => state.pageTotal,
    getCurrentDetail: (state) => state.currentDetail,
    getIsDetail: (state) => state.is_detail,
    getConfigIsChange: (state) => state.is_change,
  },

  mutations: {
    SET_SHOP_POS_LIST(state, data) {
      state.shop_pos_list = data;
    },
    SET_PAGE_TOTAL(state, data) {
      state.pageTotal = data;
    },
    SET_CURRENT_DETAIL(state, data) {
      state.currentDetail = data;
      state.is_detail = true;
      state.is_change = false;
    },
    SET_CURRENT_CONFIG(state, data) {
      state.currentDetail.config = data;
      state.is_change = false;
    },
    CLEAR_CURRENT_DETAIL(state) {
      state.currentDetail = null;
      state.is_change = false;
    },
    SET_IS_DETAIL(state, data) {
      state.is_detail = data;
    },
    SET_IS_CHANGE(state, data) {
      state.is_change = data;
    },
    SET_SEARCH_CONDITION(state, data) {
      state.search_condition = data;
    },
    CLEAR_SEARCH(state) {
      state.search_condition = null;
    },
    RESET(state) {
      state.shop_pos_list = [];
      state.search_condition = null;
      state.pageTotal = 0;
      state.currentDetail = null;
      state.is_detail = false;
      state.is_change = false;
    },
  },
  actions: {
    //總公司功能
    //取得門市機台列表
    async doGetShopPosList({ commit }) {
      const list = await shopPosList({ shop_id: [], connection_status: 0 });
      commit('SET_SHOP_POS_LIST', list.data);
      commit('SET_PAGE_TOTAL', list.data.length);
      return Promise.resolve();
    },
    //進階搜尋門市機台列表
    async doSearchShopPosList({ commit }, data) {
      let payload = JSON.parse(JSON.stringify(data));
      payload.shop_id = data.shop_id.map((e) => e.split(' ')[0]);
      payload.connection_status = data.connection_status ? data.connection_status : 0;
      const list = await shopPosList(payload);
      commit('SET_SEARCH_CONDITION', data);
      commit('SET_SHOP_POS_LIST', list.data);
      commit('SET_PAGE_TOTAL', list.data.length);
      return Promise.resolve();
    },
    //取得各門市的config
    async doGetShopConfigDetail({ commit }, item) {
      const config = await shopConfigDetail(item.shop_id);
      commit('SET_CURRENT_DETAIL', { ...item, config });
      return Promise.resolve();
    },
    //儲存各門市的config
    async doSaveShopConfigDetail({ commit, getters }, data) {
      const shop_id = getters.getCurrentDetail.shop_id;
      await saveShopConfig({ shop_id, ...data });
      commit('SET_CURRENT_CONFIG', data);
      return Promise.resolve();
    },
    //分店功能
    async doGetShopDetail() {
      const res = await shopDetail();
      return Promise.resolve(res);
    },
    async doSaveShopSetting(context, data) {
      await saveShopSetting(data);
      return Promise.resolve();
    },
  },
};
