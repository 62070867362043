export default {
  namespaced: true,
  state: {
    permission_data: [
      {
        label: '登入權限管理',
        id: 'login',
        permission: {
          backstage: {
            label: '後台登入',
            hasSwitch: false,
          },
          pos: {
            label: 'POS登入',
            hasSwitch: false,
          },
        },
      },
      {
        label: '折扣模組操作',
        id: 'discount',
        permission: {
          green_cup: {
            label: '環保杯',
            hasSwitch: true,
            value: 'green_cup_switch',
          },
          share_cup: {
            label: '分享瓶回購',
            hasSwitch: true,
            value: 'share_cup_switch',
          },
          entertain: {
            label: '整單招待',
            hasSwitch: true,
            value: 'entertain_switch',
          },
          staff_discount: {
            label: '員工優惠',
            hasSwitch: true,
            value: 'staff_discount_switch',
          },
        },
      },
    ],
    currentPermission: null,
  },

  getters: {
    getPermissionData: (state) => state.permission_data,
    getCurrentPermission: (state) => state.currentPermission,
  },

  mutations: {
    SET_CURRENT_PERMISSION(state, data) {
      state.currentPermission = data;
    },
  },

  actions: {},
};
