import request from '@/utils/request';
const baseURL = '/api/backend/';

//取得門市機台列表
export function shopPosList(data) {
  return request({
    method: 'post',
    url: `${baseURL}store/list`,
    data,
  });
}

export function shopConfigDetail(shop_id) {
  return request({
    method: 'get',
    url: `${baseURL}shop/config/detail?shop_id=${shop_id}`,
  });
}
export function saveShopConfig(data) {
  return request({
    method: 'post',
    url: `${baseURL}shop/config/save`,
    data,
  });
}

//分店設定明細
export function shopDetail() {
  return request({
    method: 'get',
    url: `${baseURL}shop/setting/detail`,
  });
}

//儲存分店設定
export function saveShopSetting(data) {
  return request({
    method: 'post',
    url: `${baseURL}shop/setting/save`,
    data,
  });
}
