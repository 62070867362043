import request from '@/utils/request';
const baseURL = '/api/backend/';

//取得訂單總攬
export function orderSummary(data) {
  return request({
    timeout: 10000,
    method: 'post',
    url: `${baseURL}order/summary`,
    data,
  });
}

//取得訂單列表
export function orderList(data) {
  return request({
    timeout: 10000,
    method: 'post',
    url: `${baseURL}order/list`,
    data,
  });
}

//取得訂單詳細
export function orderDetail(order_id) {
  return request({
    method: 'get',
    url: `${baseURL}order/detail?order_id=${order_id}`,
  });
}

//取得訂單CSV
export function orderRecord(data) {
  return request({
    method: 'post',
    url: `${baseURL}order/record`,
    data,
  });
}

//取得進階搜尋項目
export function queryInfo() {
  return request({
    method: 'get',
    url: `${baseURL}order/queryInfo`,
  });
}

//取得銷售報表總覽
export function accountingSummary(data) {
  return request({
    timeout: 10000,
    method: 'post',
    url: `${baseURL}accounting/summary`,
    data,
  });
}

//取得銷售報表列表
export function accountingList(data) {
  return request({
    timeout: 10000,
    method: 'post',
    url: `${baseURL}accounting/list`,
    data,
  });
}

//下載銷售報表CSV
export function accountingRecord(data) {
  return request({
    timeout: 10000,
    method: 'post',
    url: `${baseURL}accounting/record`,
    data,
  });
}

//取得銷售報表明細
export function accountingDetail(data) {
  return request({
    method: 'post',
    url: `${baseURL}accounting/detail`,
    data,
  });
}

//取得機台列表
export function clientList(data) {
  return request({
    method: 'post',
    url: `${baseURL}accounting/client/list`,
    data,
  });
}

//取得加密碼
export function encodedNumber(order_id) {
  return request({
    method: 'get',
    url: `${baseURL}order/getEncodedNumber?order_id=${order_id}`,
  });
}

//取得銷售分析列表
export function analysisList(data) {
  return request({
    method: 'post',
    url: `${baseURL}analysis/list`,
    data,
  });
}

//下載銷售分析CSV
export function analysisCSVFile(data) {
  return request({
    method: 'post',
    url: `${baseURL}analysis/downloadCSVFile`,
    data,
  });
}
