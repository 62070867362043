import axios from 'axios';
import store from '../store';
import { ElMessageBox, ElMessage } from 'element-plus';
import Router from '../router';

// create an axios instance
const service = axios.create({
  baseURL: process.env.baseUrl, //process.env.publicPath
  timeout: 5000, // request timeout
  loading: true, //要不要顯示loading動畫
  headers: {
    Pragma: 'no-cache',
    'Cache-Control': 'no-cache,no-store',
  },
});

service.interceptors.request.use(
  (config) => {
    if (config.loading) store.commit('user/SET_LOADING', true);
    if (store.getters['user/getSession'] || sessionStorage.getItem('session')) {
      config.headers['session'] = store.getters['user/getSession'] || sessionStorage.getItem('session');
    }
    return config;
  },
  (error) => {
    store.commit('user/SET_LOADING', false);
    return Promise.reject(error);
  }
);

let sessionExpired_flag = 0; //驗證失敗flag
let messageBox_flag = 0; //系統異常flag

service.interceptors.response.use(
  (response) => {
    store.commit('user/SET_LOADING', false);
    if (!response.data.error) {
      return response.data;
    } else {
      if (response.data.error.code === '10006') {
        if (!sessionExpired_flag) {
          sessionExpired_flag = 1;
          ElMessage.error('驗證失敗！請重新登入！');
          sessionStorage.clear();
          localStorage.clear();
          Router.push('/login');
        }
        setTimeout(() => {
          sessionExpired_flag = 0;
        }, 10000);
      } else {
        // console.log(response.data.error);
        ElMessageBox.alert(`${response.data.error.message}`, 'ERROR!', {
          type: 'error',
          confirmButtonText: '確認',
          confirmButtonClass: 'el-button--danger',
        });
      }
      return Promise.reject(response.data.error);
    }
  },
  (error) => {
    store.commit('user/SET_LOADING', false);
    if (!messageBox_flag) {
      messageBox_flag = 1;
      ElMessageBox.alert(`${error}`, '系統異常', {
        type: 'error',
        confirmButtonText: '確認',
        confirmButtonClass: 'el-button--danger',
      })
        .then(() => {
          messageBox_flag = 0;
        })
        .catch(() => {
          messageBox_flag = 0;
        });
    }
    return Promise.reject(error);
  }
);

export default service;
