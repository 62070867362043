import request from '@/utils/request';
const baseURL = '/api/backend/';

//取得媒體列表
export function mediaList(data) {
  return request({
    method: 'post',
    url: `${baseURL}setting/slideshow/list`,
    data,
  });
}

//取得媒體總數
export function mediaSummary(title) {
  return request({
    method: 'post',
    url: `${baseURL}setting/slideshow/summary`,
    data: {
      title,
    },
  });
}

//新增或修改媒體
export function saveMedia(data) {
  return request({
    method: 'post',
    url: `${baseURL}setting/slideshow/save`,
    data,
  });
}

//刪除媒體
export function deleteMedia(id) {
  return request({
    method: 'post',
    url: `${baseURL}setting/slideshow/delete`,
    data: {
      id,
    },
  });
}

//跑馬燈清單
export function marqueeList() {
  return request({
    method: 'post',
    url: `${baseURL}setting/marquee/list`,
  });
}

//修改跑馬燈
export function marqueeSave(data) {
  return request({
    method: 'post',
    url: `${baseURL}setting/marquee/save`,
    data,
  });
}
