import request from '@/utils/request';
const baseURL = '/api/backend/';

//取得訊息列表
export function notificationList(data) {
  return request({
    method: 'post',
    url: `${baseURL}notification/list`,
    data,
  });
}

//取得訊息總覽
export function notificationSummary(title) {
  return request({
    method: 'post',
    url: `${baseURL}notification/summary`,
    data: {
      title,
    },
  });
}

//新增或修改訊息
export function saveNotification(data) {
  return request({
    method: 'post',
    url: `${baseURL}notification/save`,
    data,
  });
}

//刪除訊息
export function deleteNotification(id) {
  return request({
    method: 'post',
    url: `${baseURL}notification/delete`,
    data: {
      id,
    },
  });
}

//取得小白單訊息列表
export function newsList(data) {
  return request({
    method: 'get',
    url: `${baseURL}ReceiptEventMsg/getEventMsgList?${data}`,
  });
}

//取得小白單訊息總覽
export function newsSummary(data) {
  return request({
    method: 'post',
    url: `${baseURL}ReceiptEventMsg/summary`,
    data,
  });
}

//刪除小白單訊息
export function deleteNews(event_id) {
  return request({
    method: 'post',
    url: `${baseURL}ReceiptEventMsg/deleteEventMsg`,
    data: {
      event_id,
    },
  });
}

//新增小白單訊息
export function addNews(data) {
  return request({
    method: 'post',
    url: `${baseURL}ReceiptEventMsg/addEventMsg`,
    data,
  });
}

//修改小白單訊息
export function editNews(data) {
  return request({
    method: 'post',
    url: `${baseURL}ReceiptEventMsg/editEventMsg`,
    data,
  });
}
