import request from '@/utils/request';
const baseURL = '/api/backend/';

//取得折扣列表
export function eventList(data) {
  return request({
    method: 'post',
    url: `${baseURL}event/store/list`,
    data,
  });
}

//取得折扣總數
export function eventSummary(data) {
  return request({
    method: 'post',
    url: `${baseURL}event/store/summary`,
    data,
  });
}

//新增或修改折扣
export function saveEvent(data) {
  return request({
    method: 'post',
    url: `${baseURL}event/store/save`,
    data,
  });
}

//刪除折扣
export function deleteEvent(event_id) {
  return request({
    method: 'post',
    url: `${baseURL}event/store/delete`,
    data: {
      event_id,
    },
  });
}

//取得自動優惠列表
export function autoEventList(data) {
  return request({
    method: 'post',
    url: `${baseURL}event/order/list`,
    data,
  });
}

//取得自動優惠總數
export function autoEventSummary(data) {
  return request({
    method: 'post',
    url: `${baseURL}event/order/summary`,
    data,
  });
}

//新增或修改自動優惠
export function saveAutoEvent(data) {
  return request({
    method: 'post',
    url: `${baseURL}event/order/save`,
    data,
  });
}

//刪除自動優惠
export function deleteAutoEvent(event_id) {
  return request({
    method: 'post',
    url: `${baseURL}event/order/delete`,
    data: {
      event_id,
    },
  });
}

//取得來店禮列表
export function storeGiftList(data) {
  return request({
    method: 'get',
    url: `${baseURL}WelcomeGift/getGiftList?start=${data.start ? data.start : '0'}&length=${
      data.length ? data.length : '0'
    }${data.event_name ? `&event_name=${data.event_name}` : ''}`,
  });
}

//取得來店禮總覽
export function storeGiftSummary(event_name) {
  return request({
    method: 'post',
    url: `${baseURL}WelcomeGift/summary`,
    data: {
      event_name,
    },
  });
}

//刪除來店禮
export function deleteStoreGift(event_id) {
  return request({
    method: 'post',
    url: `${baseURL}WelcomeGift/deleteGift`,
    data: {
      event_id,
    },
  });
}

//新增來店禮
export function addStoreGift(data) {
  return request({
    method: 'post',
    url: `${baseURL}WelcomeGift/addGift`,
    data,
  });
}

//修改來店禮
export function editStoreGift(data) {
  return request({
    method: 'post',
    url: `${baseURL}WelcomeGift/editGift`,
    data,
  });
}

// //取得來店禮兌換紀錄
export function storeGiftRecordList(data) {
  return request({
    method: 'post',
    url: `${baseURL}WelcomeGift/getGiftRecord`,
    data,
  });
}

//取得來店禮兌換紀錄總覽
export function storeGiftRecordSummary(data) {
  return request({
    method: 'post',
    url: `${baseURL}WelcomeGift/getRecordSummary`,
    data,
  });
}

//取消來店禮兌換
export function cancelStoreGift(data) {
  return request({
    method: 'post',
    url: `${baseURL}WelcomeGift/deleteGiftRecord`,
    data,
  });
}
