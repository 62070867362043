import { newsList, newsSummary, deleteNews, addNews, editNews } from '@/api/information';
export default {
  namespaced: true,
  state: {
    news_list: [],
    pageSize: 10,
    pageTotal: 0,
    search_condition: null,
  },

  getters: {
    getNewsList: (state) => state.news_list,
    getPageSize: (state) => state.pageSize,
    getPageTotal: (state) => state.pageTotal,
    getSearchCondition: (state) => state.search_condition,
  },

  mutations: {
    SET_NEWS_LIST(state, data) {
      state.news_list = data;
    },
    SET_PAGE_TOTAL(state, data) {
      state.pageTotal = data;
    },
    SET_SEARCH_CONDITION(state, data) {
      state.search_condition = data;
    },
    RESET(state) {
      state.news_list = [];
      state.pageTotal = 0;
      state.search_condition = null;
    },
  },
  actions: {
    //取得小白單訊息清單
    async doGetNewsList({ getters, commit }, data) {
      //整理送出資料
      const payload = {
        ...JSON.parse(JSON.stringify(data)),
        length: getters.getPageSize,
        start_date: data.date ? data.date[0] : '',
        end_date: data.date ? data.date[1] : '',
      };
      delete payload.date;
      if (payload.available_status == '') delete payload.available_status;

      let payloadKeys = Object.keys(payload);
      let payloadtext = `${payloadKeys[0]}=${payload[payloadKeys[0]]}`;
      for (let i = 1; i < payloadKeys.length; i++) {
        payloadtext += `&${payloadKeys[i]}=${payload[payloadKeys[i]]}`;
      }
      // console.log('payloadtext', payloadtext);
      //取得資料
      const list = await newsList(payloadtext);
      list.data.forEach((item) => {
        // 目前無縮圖，先設定為空字串
        item.media_url = '';
        item.media_name = '';

        // 內容JSON.parse
        try {
          // console.log('item.content---', JSON.parse(item.content));
          item.content = JSON.parse(item.content);
        } catch (e) {
          item.content = [
            {
              height: false,
              width: false,
              bold: false,
              message: '',
            },
          ];
        }
      });
      commit('SET_NEWS_LIST', list.data);
    },

    //取得小白單訊息總覽
    async doGetNewsSummary({ commit }, data) {
      try {
        const payload = {
          ...JSON.parse(JSON.stringify(data)),
          start_date: data.date ? data.date[0] : '',
          end_date: data.date ? data.date[1] : '',
        };
        delete payload.date;

        const sum = await newsSummary({ ...payload });
        commit('SET_PAGE_TOTAL', sum.total_count);
        return Promise.resolve();
      } catch {
        commit('SET_NEWS_LIST', []);
        return Promise.reject();
      }
    },

    //刪除訊息
    async doDeleteNews(context, event_id) {
      await deleteNews(event_id);
      return Promise.resolve();
    },

    //新增訊息
    async doAddNews(context, data) {
      let payload = JSON.parse(JSON.stringify(data));
      payload.content = JSON.stringify(payload.content);
      await addNews(payload);
      return Promise.resolve();
    },

    //修改訊息
    async doEditNews(context, data) {
      let payload = JSON.parse(JSON.stringify(data));
      payload.content = JSON.stringify(payload.content);
      await editNews(payload);
      return Promise.resolve();
    },
  },
};
