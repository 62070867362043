import { storeGiftRecordList, storeGiftRecordSummary, cancelStoreGift } from '@/api/discount';

export default {
  namespaced: true,
  state: {
    store_gift_record: [],
    search_condition: null,
    pageSize: 10,
    pageTotal: 0,
  },

  getters: {
    getStoreGiftRecord: (state) => state.store_gift_record,
    getSearchCondition: (state) => state.search_condition,
    getPageSize: (state) => state.pageSize,
    getPageTotal: (state) => state.pageTotal,
  },

  mutations: {
    SET_STORE_GIFT_RECORD(state, data) {
      state.store_gift_record = data;
    },
    SET_SEARCH_CONDITION(state, data) {
      state.search_condition = data;
    },
    SET_PAGE_TOTAL(state, data) {
      state.pageTotal = data;
    },
    RESET(state) {
      state.store_gift_record = [];
      state.search_condition = null;
      state.pageTotal = 0;
    },
  },
  actions: {
    //取得來店禮兌換紀錄 storeGiftRecordList
    async doGetStoreGiftRecord({ getters, commit }, data) {
      let payload = JSON.parse(JSON.stringify(data));
      if (payload.shop_id == null) payload.shop_id = '';
      if (payload.shop_id != '') payload.shop_id = payload.shop_id.split(' ')[1];
      if (payload.date) {
        payload.start_date = data.date[0];
        payload.end_date = data.date[1];
      }
      delete payload.date;

      // 取得紀錄列表
      let res = await storeGiftRecordList({
        ...payload,
        length: getters.getPageSize,
      });
      commit('SET_STORE_GIFT_RECORD', res.data);
      // 取得總筆數
      let sum = await storeGiftRecordSummary({
        ...payload,
      });
      commit('SET_PAGE_TOTAL', sum.total_count);

      return Promise.resolve();
    },

    //取消來店禮兌換
    async doCancelStoreGift(context, data) {
      let res = await cancelStoreGift(data);
      if (res.message) return Promise.resolve(res.message);
      else if (res.error) return Promise.resolve(res.error.message);
      else return Promise.resolve('');
    },
  },
};
