import { notificationList, notificationSummary, saveNotification, deleteNotification } from '@/api/information';
export default {
  namespaced: true,
  state: {
    notification_list: [],
    pageSize: 10,
    pageTotal: 0,
  },

  getters: {
    getNotificationList: (state) => state.notification_list,
    getPageSize: (state) => state.pageSize,
    getPageTotal: (state) => state.pageTotal,
  },

  mutations: {
    SET_NOTIFICATION_LIST(state, data) {
      state.notification_list = data;
    },
    SET_PAGE_TOTAL(state, data) {
      state.pageTotal = data;
    },
    RESET(state) {
      state.notification_list = [];
      state.pageTotal = 0;
    },
  },
  actions: {
    //取得訊息清單
    async doGetNotificationList({ getters, commit }, data) {
      try {
        const payload = { ...data, length: getters.getPageSize };
        const list = await notificationList(payload);
        commit('SET_NOTIFICATION_LIST', list.data);
        return Promise.resolve();
      } catch {
        commit('SET_NOTIFICATION_LIST', []);
        return Promise.reject();
      }
    },
    //取得訊息列表總覽
    async doGetNotificationSummary({ commit }, title) {
      try {
        const sum = await notificationSummary(title);
        commit('SET_PAGE_TOTAL', sum.total_count);
        return Promise.resolve();
      } catch {
        commit('SET_NOTIFICATION_LIST', []);
        return Promise.reject();
      }
    },
    //新增或修改訊息
    async doSaveNotification(context, data) {
      await saveNotification(data);
      return Promise.resolve();
    },
    //刪除訊息
    async doDeleteNotification(context, id) {
      await deleteNotification(id);
      return Promise.resolve();
    },
  },
};
