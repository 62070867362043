import request from '@/utils/request';
const baseURL = '/api/backend/';

//取得ubereats授權商店列表
export function getUberEatsData(data) {
  return request({
    method: 'post',
    url: `${baseURL}ubereats/getStoreList`,
    data,
  });
}
//綁定ubereats商店
export function bindUberEats(data) {
  return request({
    method: 'post',
    url: `${baseURL}ubereats/activateIntegration`,
    data,
  });
}
//解除綁定ubereats商店
export function unbindUberEats(data) {
  return request({
    method: 'post',
    url: `${baseURL}ubereats/unActivateIntegration`,
    data,
  });
}
