import { orderList, orderSummary, orderDetail, queryInfo, encodedNumber, orderRecord } from '@/api/order';
export default {
  namespaced: true,
  state: {
    order_list: [],
    search_condition: null,
    pageSize: 10,
    pageTotal: 0,
    options: null,
    orderDetail: null,
    is_detail: false,
    encodedNum: '',
  },

  getters: {
    getOrderList: (state) => state.order_list,
    getSearchCondition: (state) => state.search_condition,
    getPageSize: (state) => state.pageSize,
    getPageTotal: (state) => state.pageTotal,
    getOptions: (state) => state.options,
    getOrderDetail: (state) => state.orderDetail,
    getIsDetail: (state) => state.is_detail,
    getEncodedNumber: (state) => state.encodedNum,
  },

  mutations: {
    SET_ORDER_LIST(state, data) {
      state.order_list = data;
    },
    SET_PAGE_TOTAL(state, data) {
      state.pageTotal = data;
    },
    SET_SEARCH_OPTIONS(state, data) {
      state.options = data;
    },
    SET_ORDER_DETAIL(state, data) {
      state.orderDetail = data;
      state.is_detail = true;
    },
    SET_IS_DETAIL(state, data) {
      state.is_detail = data;
    },
    CLEAR_ORDER_DETAIL(state) {
      state.orderDetail = null;
    },
    CLEAR_ENCODED_NUMBER(state) {
      state.encodedNum = '';
    },
    SET_SEARCH_CONDITION(state, data) {
      state.search_condition = data;
    },
    SET_ENCODED_NUMBER(state, data) {
      state.encodedNum = data;
    },
    RESET(state) {
      state.order_list = [];
      state.search_condition = null;
      state.pageTotal = 0;
      state.options = null;
      state.orderDetail = null;
      state.is_detail = false;
      state.encodedNum = '';
    },
  },

  actions: {
    //搜尋訂單
    async doSearchOrderList({ getters, commit }, data) {
      let payload = {
        ...data.search,
        start: data.start,
        length: getters.getPageSize,
        start_order_time: data.search.date[0],
        end_order_time: data.search.date[1],
      };
      if (payload.shop_id) payload.shop_id = payload.shop_id.split(' ')[0];
      else payload.shop_id = '';
      if (!payload.invoice_status && payload.invoice_status !== 0) delete payload.invoice_status;
      const list = await orderList(payload);
      if (data.type === 'search') {
        const sum = await orderSummary(payload);
        commit('SET_PAGE_TOTAL', sum.total_count);
      }
      commit('SET_SEARCH_CONDITION', data.search);
      commit('SET_ORDER_LIST', list.data);
      return Promise.resolve();
    },
    //訂單詳細
    async doGetOrderDetail({ commit }, item) {
      const res = await orderDetail(item.order_id);
      commit('SET_ORDER_DETAIL', { ...res, create_datetime: item.create_datetime });
      return Promise.resolve();
    },
    //取得訂單進階搜尋各式選項
    async doQueryOptions({ getters, commit }) {
      if (!getters.getOptions) {
        const options = await queryInfo();
        commit('SET_SEARCH_OPTIONS', options);
      }
      return Promise.resolve();
    },
    //取得加密碼
    async doGetEncodedNumber({ commit }, item) {
      const res = await encodedNumber(item.order_id);
      commit('SET_ENCODED_NUMBER', res.encoded_number);
      return Promise.resolve();
    },
    //取得訂單CSV
    async doGetOrderRecord(context, data) {
      const res = await orderRecord({
        shop_id: data.shop_id.split(' ')[0],
        start_order_time: data.date[0],
        end_order_time: data.date[1],
        status: data.status,
        source_platform: data.source_platform,
        take_away_method: data.take_away_method,
        invoice_no: data.invoice_no,
        serial_number: data.serial_number,
      });
      return Promise.resolve(res);
    },
  },
};
