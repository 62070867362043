import { getUberEatsData, bindUberEats, unbindUberEats } from '@/api/externalPlatform';

export default {
  namespaced: true,
  state: {
    access_token: 'noToken',
    ubereats_store_list: [],
  },

  getters: {
    getAccessToken: (state) => state.access_token,
    getUberEatsStoreList: (state) => state.ubereats_store_list,
  },

  mutations: {
    SET_ACCESS_TOKEN(state, str) {
      state.access_token = str;
    },
    SET_UE_STORE_LIST(state, arr) {
      state.ubereats_store_list = arr;
    },
    RESET(state) {
      state.access_token = 'noToken';
      state.ubereats_store_list = [];
    },
  },

  actions: {
    // 取得UberEats授權資料
    async doGetUberEatsData({ commit }, data) {
      let res = await getUberEatsData(data);
      commit('SET_UE_STORE_LIST', res.store_list);
      return Promise.resolve();
    },
    // 綁定UberEats
    async doBindUberEats(context, data) {
      let res = await bindUberEats(data);
      return Promise.resolve(res);
    },
    // 解除綁定UberEats
    async doUnBindUberEats(context, data) {
      let res = await unbindUberEats(data);
      return Promise.resolve(res);
    },
  },
};
