import { autoEventList, autoEventSummary, saveAutoEvent, deleteAutoEvent } from '@/api/discount';
import {
  getProductCategoryList,
  getProductCategoryTree,
  getProductInfo,
  getProductOptionInfo,
  getCategoryOptionInfo,
} from '@/api/common';
export default {
  namespaced: true,
  state: {
    discount_list: [],
    search_condition: null,
    pageSize: 10,
    pageTotal: 0,
    product_size: [],
    giveaway_size: [],
    replace_product_size: [],
  },

  getters: {
    getAutoDiscountList: (state) => state.discount_list,
    getSearchCondition: (state) => state.search_condition,
    getPageSize: (state) => state.pageSize,
    getPageTotal: (state) => state.pageTotal,
    getProductSize: (state) => state.product_size,
    getGiveawaySize: (state) => state.giveaway_size,
    getReplaceProductSize: (state) => state.replace_product_size,
  },

  mutations: {
    SET_DISCOUNT_LIST(state, data) {
      state.discount_list = data;
    },
    SET_PAGE_TOTAL(state, data) {
      state.pageTotal = data;
    },
    SET_PRODUCT_SIZE(state, data) {
      state.product_size = data;
    },
    SET_GIVEAWAY_SIZE(state, data) {
      state.giveaway_size = data;
    },
    SET_REPLACE_PRODUCT_SIZE(state, data) {
      state.replace_product_size = data;
    },
    CLEAR_OPTIONS(state) {
      state.product_size = [];
      state.giveaway_size = [];
      state.replace_product_size = [];
    },
    CLEAR_REPLACE_PRODUCT_OPTION(state) {
      state.replace_product_size = [];
    },
    SET_SEARCH_CONDITION(state, data) {
      state.search_condition = data;
    },
    RESET(state) {
      state.discount_list = [];
      state.search_condition = null;
      state.pageTotal = 0;
      state.product_size = [];
      state.giveaway_size = [];
      state.replace_product_size = [];
    },
  },
  actions: {
    //取得自動優惠清單
    async doGetAutoDiscountList({ getters, commit }, data) {
      let payload = {
        ...data.search,
        start: data.start,
        length: getters.getPageSize,
        available_start_date: '',
        available_end_date: '',
      };
      if (payload.date) {
        payload.available_start_date = data.search.date[0];
        payload.available_end_date = data.search.date[1];
      }
      if (payload.shop_id) payload.shop_id = payload.shop_id.split(' ')[0];
      if (!payload.available_status && payload.available_status !== 0) delete payload.available_status;
      const list = await autoEventList(payload);
      commit('SET_SEARCH_CONDITION', data.search);
      commit('SET_DISCOUNT_LIST', list.data);
      return Promise.resolve();
    },
    //取得自動優惠列表總覽
    async doGetAutoDiscountSummary({ commit }, data) {
      let payload = { ...data, available_start_date: '', available_end_date: '' };
      if (payload.date) {
        payload.available_start_date = data.date[0];
        payload.available_end_date = data.date[1];
      }
      if (payload.shop_id) payload.shop_id = payload.shop_id.split(' ')[0];
      if (!payload.available_status && payload.available_status !== 0) delete payload.available_status;
      const sum = await autoEventSummary(payload);
      commit('SET_PAGE_TOTAL', sum.total_count);
      return Promise.resolve();
    },
    //新增或修改自動優惠
    async doSaveAutoDiscount(context, data) {
      await saveAutoEvent(data);
      return Promise.resolve();
    },
    //刪除自動優惠
    async doDeleteAutoDiscount(context, id) {
      await deleteAutoEvent(id);
      return Promise.resolve();
    },
    //取得商品類別列表、商品樹、商品列表
    async doGetProductInfo({ commit, rootGetters }) {
      if (!rootGetters['common/getCategoryList']) {
        const cate = await getProductCategoryList();
        commit('common/SET_CATEGORY_LIST', cate.data, { root: true });
      }
      if (!rootGetters['common/getProductTree']) {
        const tree = await getProductCategoryTree();
        commit('common/SET_PRODUCT_TREE', tree.data, { root: true });
      }
      if (!rootGetters['common/getProductList']) {
        const list = await getProductInfo();
        commit('common/SET_PRODUCT_LIST', list.data, { root: true });
      }
      return Promise.resolve();
    },
    //取得類別或商品對應容量
    async doGetProductOption({ commit }, data) {
      let res;
      if (data.type === 'product') {
        //商品
        res = await getProductOptionInfo(data.id);
      } else {
        //類別
        res = await getCategoryOptionInfo(data.id);
      }
      commit('SET_PRODUCT_SIZE', res.size);
      return Promise.resolve();
    },
    //取得贈品對應容量
    async doGetGiveawayOption({ commit }, id) {
      const res = await getProductOptionInfo(id);
      commit('SET_GIVEAWAY_SIZE', res.size);
      return Promise.resolve();
    },
    //取得贈品對應容量
    async doGetReplaceProductOption({ commit }, id) {
      const res = await getProductOptionInfo(id);
      commit('SET_REPLACE_PRODUCT_SIZE', res.size);
      return Promise.resolve();
    },
  },
};
