import request from '@/utils/request';
const baseURL = '/api/backend/';

//取得拒單原因、延遲訂單原因列表
export function reasonList() {
  return request({
    method: 'get',
    url: `${baseURL}store/config/list`,
  });
}

//修改拒單原因、延遲訂單原因列表
export function saveReasonList(data) {
  return request({
    method: 'post',
    url: `${baseURL}store/config/save`,
    data,
  });
}
