import { userLogin, getStoreInfo, userLogout } from '@/api/user';
import Router from '../../router';
import { ElMessage } from 'element-plus';
export default {
  namespaced: true,
  state: {
    all_store_list: [],
    session: null,
    loading: false,
    sideBarCollapse: false,
    version: process.env.VUE_APP_VERSION,
    environment: process.env.VUE_APP_ENV,
    permissionTree: [],
  },

  getters: {
    getAllStoreList: (state) => state.all_store_list,
    getSession: (state) => state.session,
    getLoading: (state) => state.loading,
    getSideBarCollapse: (state) => state.sideBarCollapse,
    getVersion: (state) => state.version,
    getEnvironment: (state) => state.environment,
    getPermissionTree: (state) => state.permissionTree,
  },

  mutations: {
    SET_STORE_LIST(state, data) {
      const new_list = [];
      data.forEach((area, a_index) => {
        new_list.push({ label: area.name, value: area.name, children: [] });
        area.city.forEach((city, c_index) => {
          new_list[a_index].children.push({ label: city.name, value: city.name, children: [] });
          city.store.forEach((store) => {
            new_list[a_index].children[c_index].children.push({
              label: store.shop_id + ' ' + store.name,
              value: store.shop_id + ' ' + store.name,
              name: store.name,
            });
          });
        });
      });
      state.all_store_list = new_list;
    },
    SET_SESSION(state, data) {
      state.session = data;
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
    TOGGLE_SIDEBAR(state) {
      state.sideBarCollapse = !state.sideBarCollapse;
    },
    SET_COLLAPSE(state, data) {
      state.sideBarCollapse = data;
    },
    SET_PERMISSION_TREE(state, data) {
      state.permissionTree = data;
    },
  },

  actions: {
    async doLogin({ commit }, data) {
      const res = await userLogin(data);
      sessionStorage.setItem('shop', data.shop);
      sessionStorage.setItem('session', res.session);
      sessionStorage.setItem('user_name', res.user_name);
      sessionStorage.setItem('is_headquarters', res.is_headquarters);
      commit('SET_SESSION', res.session);
      commit('SET_PERMISSION_TREE', res.permission_tree);
      ElMessage.success('登入成功');
      return Promise.resolve();
    },
    async doGetStore({ commit }) {
      const res = await getStoreInfo();
      commit('SET_STORE_LIST', res.data);
      return Promise.resolve();
    },
    async doLogout({ commit }) {
      await userLogout();
      ElMessage.success('登出成功');
      sessionStorage.clear();
      localStorage.clear();
      //清空資料
      commit('SET_SESSION', null);
      commit('SET_PERMISSION_TREE', []);
      commit('common/RESET', '', { root: true });
      commit('order/RESET', '', { root: true });
      commit('report/RESET', '', { root: true });
      commit('manualDiscount/RESET', '', { root: true });
      commit('autoDiscount/RESET', '', { root: true });
      commit('default/RESET', '', { root: true });
      commit('meal/RESET', '', { root: true });
      commit('product/RESET', '', { root: true });
      commit('shopSystem/RESET', '', { root: true });
      commit('media/RESET', '', { root: true });
      commit('marquee/RESET', '', { root: true });
      commit('notification/RESET', '', { root: true });
      commit('account/RESET', '', { root: true });
      Router.push('/login');
      return Promise.resolve();
    },
  },
};
