import { storeGiftList, storeGiftSummary, deleteStoreGift, addStoreGift, editStoreGift } from '@/api/discount';
import { getProductCategoryTree } from '@/api/common';

export default {
  namespaced: true,
  state: {
    store_gift_list: [],
    pageSize: 10,
    pageTotal: 0,
  },

  getters: {
    getStoreGiftList: (state) => state.store_gift_list,
    getPageSize: (state) => state.pageSize,
    getPageTotal: (state) => state.pageTotal,
  },

  mutations: {
    SET_STORE_GIFT_LIST(state, data) {
      state.store_gift_list = data;
    },
    SET_PAGE_TOTAL(state, data) {
      state.pageTotal = data;
    },
    RESET(state) {
      state.store_gift_list = [];
      state.pageTotal = 0;
    },
  },
  actions: {
    //取得來店禮列表
    async doGetStoreGiftList({ getters, commit }, data) {
      const payload = { ...data, length: getters.getPageSize };
      // console.log('doGetStoreGiftList----data', payload);
      let list = await storeGiftList(payload);
      commit('SET_STORE_GIFT_LIST', list.data);
      // console.log('來店禮列表', list.data);
    },

    //新增活動
    async doAddStoreGift(context, data) {
      await addStoreGift(data);
      return Promise.resolve();
    },

    //修改活動
    async doEditStoreGift(context, data) {
      await editStoreGift(data);
      return Promise.resolve();
    },

    //刪除訊息
    async doDeleteStoreGift(context, id) {
      await deleteStoreGift(id);
      return Promise.resolve();
    },

    //取得商品清單
    async doGetProductTree({ commit, rootGetters }) {
      if (!rootGetters['common/getProductTree']) {
        const tree = await getProductCategoryTree();
        commit('common/SET_PRODUCT_TREE', tree.data, { root: true });
      }
      return Promise.resolve();
    },

    //取得來店禮總覽
    async doGetStoreGiftSummary({ commit }, event_name) {
      try {
        const sum = await storeGiftSummary(event_name);
        // console.log('來店禮總覽', sum);
        commit('SET_PAGE_TOTAL', sum.total_count);
        return Promise.resolve();
      } catch {
        commit('SET_STORE_GIFT_LIST', []);
        return Promise.reject();
      }
    },
  },
};
