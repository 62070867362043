import { mediaList, mediaSummary, saveMedia, deleteMedia } from '@/api/mediaMarquee';
export default {
  namespaced: true,
  state: {
    media_list: [],
    pageSize: 10,
    pageTotal: 0,
  },

  getters: {
    getMediaList: (state) => state.media_list,
    getPageSize: (state) => state.pageSize,
    getPageTotal: (state) => state.pageTotal,
  },

  mutations: {
    SET_MEDIA_LIST(state, data) {
      state.media_list = data;
    },
    SET_PAGE_TOTAL(state, data) {
      state.pageTotal = data;
    },
    RESET(state) {
      state.media_list = [];
      state.pageTotal = 0;
    },
  },
  actions: {
    //取得媒體清單
    async doGetMediaList({ getters, commit }, data) {
      try {
        const payload = { ...data, length: getters.getPageSize };
        const list = await mediaList(payload);
        commit('SET_MEDIA_LIST', list.data);
        return Promise.resolve();
      } catch {
        commit('SET_MEDIA_LIST', []);
        return Promise.reject();
      }
    },
    //取得媒體列表總覽
    async doGetMediaSummary({ commit }, title) {
      try {
        const sum = await mediaSummary(title);
        commit('SET_PAGE_TOTAL', sum.total_count);
        return Promise.resolve();
      } catch {
        commit('SET_MEDIA_LIST', []);
        return Promise.reject();
      }
    },
    //新增或修改媒體
    async doSaveMedia(context, data) {
      await saveMedia(data);
      return Promise.resolve();
    },
    //刪除媒體
    async doDeleteMedia(context, id) {
      await deleteMedia(id);
      return Promise.resolve();
    },
  },
};
