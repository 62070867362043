import { accountingList, accountingSummary, accountingDetail, clientList, accountingRecord } from '@/api/order';
import { formatDateTime } from '@/utils/datetime';
export default {
  namespaced: true,
  state: {
    report_list: [],
    csv_list: [],
    search_condition: null,
    pageSize: 10,
    pageTotal: 0,
    client_list: [],
    currentIndex: null,
    reportDetail: null,
    is_detail: false,
  },

  getters: {
    getReportList: (state) => state.report_list,
    getCSVList: (state) => state.csv_list,
    getSearchCondition: (state) => state.search_condition,
    getPageSize: (state) => state.pageSize,
    getPageTotal: (state) => state.pageTotal,
    getClientList: (state) => state.client_list,
    getCurrentIndex: (state) => state.currentIndex,
    getReportDetail: (state) => state.reportDetail,
    getIsDetail: (state) => state.is_detail,
  },

  mutations: {
    SET_REPORT_LIST(state, data) {
      state.report_list = data;
    },
    SET_CSV_LIST(state, data) {
      state.csv_list = data;
      const excludedFields = [
        'shop_id',
        'initial_cash',
        'cash_box',
        'delivery',
        'inside',
        'juton',
        'member_card',
        'on_site',
        'phone',
        'pick_up',
        'third_party_pay',
      ];
      const filteredData = data.map((obj) =>
        Object.keys(obj).reduce((acc, key) => {
          if (!excludedFields.includes(key)) {
            acc[key] = obj[key];
          }
          return acc;
        }, {})
      );
      state.csv_list = filteredData;
    },
    SET_PAGE_TOTAL(state, data) {
      state.pageTotal = data;
    },
    SET_CLIENT_LIST(state, data) {
      state.client_list = data;
    },
    SET_CURRENT_INDEX(state, data) {
      state.currentIndex = data;
    },
    SET_REPORT_DETAIL(state, data) {
      state.reportDetail = data;
      state.is_detail = true;
    },
    SET_IS_DETAIL(state, data) {
      state.is_detail = data;
    },
    CLEAR_REPORT_DETAIL(state) {
      state.reportDetail = null;
      state.currentIndex = null;
      state.client_list = [];
    },
    SET_SEARCH_CONDITION(state, data) {
      state.search_condition = data;
    },
    RESET(state) {
      state.report_list = [];
      state.csv_list = [];
      state.search_condition = null;
      state.pageTotal = 0;
      state.client_list = [];
      state.currentIndex = null;
      state.reportDetail = null;
      state.is_detail = false;
    },
  },

  actions: {
    //搜尋報表
    async doSearchReportList({ getters, commit }, data) {
      let payload = {
        ...data.search,
        start: data.start,
        length: getters.getPageSize,
        start_date: formatDateTime('YYYYMMDD', data.search.date[0]),
        end_date: formatDateTime('YYYYMMDD', data.search.date[1]),
      };
      payload.shop_id = data.search.shop_id.map((e) => e.split(' ')[0]);
      const list = await accountingList(payload);
      if (data.type === 'search') {
        const sum = await accountingSummary(payload);
        commit('SET_PAGE_TOTAL', sum.total_count);
      }
      commit('SET_SEARCH_CONDITION', data.search);
      commit('SET_REPORT_LIST', list.data);
      return Promise.resolve();
    },
    //下載csv
    async doCSVList({ getters, commit }, data) {
      let payload = {
        ...data.search,
        start: data.start,
        length: getters.getPageSize,
        start_date: formatDateTime('YYYYMMDD', data.search.date[0]),
        end_date: formatDateTime('YYYYMMDD', data.search.date[1]),
      };
      payload.shop_id = data.search.shop_id.map((e) => e.split(' ')[0]);
      const csvList = await accountingRecord(payload);
      commit('SET_CSV_LIST', csvList.data);
      return Promise.resolve();
    },
    //機台列表
    async doGetClientList({ commit }, data) {
      const res = await clientList(data);
      commit('SET_CLIENT_LIST', res.data);
      return Promise.resolve();
    },
    //報表詳細
    async doGetReportDetail({ commit }, data) {
      const res = await accountingDetail(data);
      commit('SET_REPORT_DETAIL', res);
      return Promise.resolve();
    },
  },
};
