import request from '@/utils/request';
const baseURL = '/api/backend/';

//取得套餐列表
export function mealList(data) {
  return request({
    method: 'post',
    url: `${baseURL}event/list`,
    data,
  });
}

//取得套餐總數
export function mealSummary(data) {
  return request({
    method: 'post',
    url: `${baseURL}event/summary`,
    data,
  });
}

//新增或修改套餐
export function saveMeal(data) {
  return request({
    method: 'post',
    url: `${baseURL}event/save`,
    data,
  });
}

//刪除套餐
export function deleteMeal(event_id) {
  return request({
    method: 'post',
    url: `${baseURL}event/delete`,
    data: {
      event_id,
    },
  });
}
