import { mealList, mealSummary, saveMeal, deleteMeal } from '@/api/meal';
import { getProductInfo, getProductCategoryTree, getProductOptionInfo } from '@/api/common';
export default {
  namespaced: true,
  state: {
    meal_list: [],
    search_condition: null,
    pageSize: 10,
    pageTotal: 0,
  },

  getters: {
    getMealList: (state) => state.meal_list,
    getSearchCondition: (state) => state.search_condition,
    getPageSize: (state) => state.pageSize,
    getPageTotal: (state) => state.pageTotal,
  },

  mutations: {
    SET_MEAL_LIST(state, data) {
      state.meal_list = data;
    },
    SET_SEARCH_CONDITION(state, data) {
      state.search_condition = data;
    },
    SET_PAGE_TOTAL(state, data) {
      state.pageTotal = data;
    },
    RESET(state) {
      state.meal_list = [];
      state.pageTotal = 0;
      state.search_condition = null;
    },
  },
  actions: {
    //取得套餐清單
    async doGetMealList({ getters, commit }, data) {
      let payload = {
        ...data.search,
        start: data.start,
        length: getters.getPageSize,
        available_start_date: '',
        available_end_date: '',
      };
      if (payload.date) {
        payload.available_start_date = data.search.date[0];
        payload.available_end_date = data.search.date[1];
      }
      if (payload.shop_id) payload.shop_id = payload.shop_id.split(' ')[0];
      if (!payload.available_status && payload.available_status !== 0) delete payload.available_status;
      const list = await mealList(payload);
      commit('SET_SEARCH_CONDITION', data.search);
      commit('SET_MEAL_LIST', list.data);
      return Promise.resolve();
    },
    //取得套餐列表總覽
    async doGetMealSummary({ commit }, data) {
      let payload = { ...data, available_start_date: '', available_end_date: '' };
      if (payload.date) {
        payload.available_start_date = data.date[0];
        payload.available_end_date = data.date[1];
      }
      if (payload.shop_id) payload.shop_id = payload.shop_id.split(' ')[0];
      if (!payload.available_status && payload.available_status !== 0) delete payload.available_status;
      const sum = await mealSummary(payload);
      commit('SET_PAGE_TOTAL', sum.total_count);
      return Promise.resolve();
    },
    //新增或修改套餐
    async doSaveMeal(context, data) {
      await saveMeal(data);
      return Promise.resolve();
    },
    //刪除套餐
    async doDeleteMeal(context, id) {
      await deleteMeal(id);
      return Promise.resolve();
    },
    //取得商品清單
    async doGetProductList({ commit, rootGetters }) {
      if (!rootGetters['common/getProductTree']) {
        const tree = await getProductCategoryTree();
        commit('common/SET_PRODUCT_TREE', tree.data, { root: true });
      }
      if (!rootGetters['common/getProductList']) {
        const list = await getProductInfo();
        commit('common/SET_PRODUCT_LIST', list.data, { root: true });
      }
      return Promise.resolve();
    },
    //取得商品選項
    async doGetProductOptions(context, id) {
      const res = await getProductOptionInfo(id);
      return Promise.resolve(res);
    },
  },
};
