import { eventList, eventSummary, saveEvent, deleteEvent } from '@/api/discount';
export default {
  namespaced: true,
  state: {
    discount_list: [],
    search_condition: null,
    pageSize: 10,
    pageTotal: 0,
  },

  getters: {
    getManualDiscountList: (state) => state.discount_list,
    getSearchCondition: (state) => state.search_condition,
    getPageSize: (state) => state.pageSize,
    getPageTotal: (state) => state.pageTotal,
  },

  mutations: {
    SET_DISCOUNT_LIST(state, data) {
      state.discount_list = data;
    },
    SET_PAGE_TOTAL(state, data) {
      state.pageTotal = data;
    },
    SET_SEARCH_CONDITION(state, data) {
      state.search_condition = data;
    },
    RESET(state) {
      state.discount_list = [];
      state.search_condition = null;
      state.pageTotal = 0;
    },
  },
  actions: {
    //取得折扣清單
    async doGetManualDiscountList({ getters, commit }, data) {
      let payload = {
        ...data.search,
        start: data.start,
        length: getters.getPageSize,
        available_start_date: '',
        available_end_date: '',
      };
      if (payload.date) {
        payload.available_start_date = data.search.date[0];
        payload.available_end_date = data.search.date[1];
      }
      if (payload.shop_id) payload.shop_id = payload.shop_id.split(' ')[0];
      if (!payload.available_status && payload.available_status !== 0) delete payload.available_status;
      const list = await eventList(payload);
      commit('SET_SEARCH_CONDITION', data.search);
      commit('SET_DISCOUNT_LIST', list.data);
      return Promise.resolve();
    },
    //取得折扣列表總覽
    async doGetManualDiscountSummary({ commit }, data) {
      let payload = { ...data, available_start_date: '', available_end_date: '' };
      if (payload.date) {
        payload.available_start_date = data.date[0];
        payload.available_end_date = data.date[1];
      }
      if (payload.shop_id) payload.shop_id = payload.shop_id.split(' ')[0];
      if (!payload.available_status && payload.available_status !== 0) delete payload.available_status;
      const sum = await eventSummary(payload);
      commit('SET_PAGE_TOTAL', sum.total_count);
      return Promise.resolve();
    },
    //新增或修改折扣
    async doSaveManualDiscount(context, data) {
      await saveEvent(data);
      return Promise.resolve();
    },
    //刪除折扣
    async doDeleteManualDiscount(context, id) {
      await deleteEvent(id);
      return Promise.resolve();
    },
  },
};
