import request from '@/utils/request';
const baseURL = '/api/backend/';

//取得帳號列表
export function userList(data) {
  return request({
    method: 'post',
    url: `${baseURL}user/list`,
    data,
  });
}

//取得帳號總覽
export function userSummary(data) {
  return request({
    method: 'post',
    url: `${baseURL}user/summary`,
    data,
  });
}

//取得權限角色列表
export function permissionList(data) {
  return request({
    method: 'post',
    url: `${baseURL}permission/list`,
    data,
  });
}

//權限總覽
export function permissionSummary(data) {
  return request({
    method: 'post',
    url: `${baseURL}permission/summary`,
    data,
  });
}

//權限列表
export function permissionTree(data) {
  return request({
    method: 'post',
    url: `${baseURL}permission/tree`,
    data,
  });
}

//權限角色新增/編輯
export function permissionSave(data) {
  return request({
    method: 'post',
    url: `${baseURL}permission/save`,
    data,
  });
}

//刪除權限角色
export function permissionDelete(data) {
  return request({
    method: 'post',
    url: `${baseURL}permission/delete`,
    data,
  });
}

//角色帳號列表
export function permissionUserList(data) {
  return request({
    method: 'post',
    url: `${baseURL}permission/userList`,
    data,
  });
}
