import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/main.scss';

//引入element plus
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

//全局註冊bootstrap icon
import 'bootstrap-icons/font/bootstrap-icons.css';

const app = createApp(App);
app.use(store).use(router).use(ElementPlus).mount('#app');
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
