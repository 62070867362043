import { userList, userSummary } from '@/api/account';
export default {
  namespaced: true,
  state: {
    account_list: [],
    search_condition: null,
    pageSize: 10,
    pageTotal: 0,
    accountDetail: null,
    is_detail: false,
  },

  getters: {
    getAccountList: (state) => state.account_list,
    getSearchCondition: (state) => state.search_condition,
    getPageSize: (state) => state.pageSize,
    getPageTotal: (state) => state.pageTotal,
    getAccountDetail: (state) => state.accountDetail,
    getIsDetail: (state) => state.is_detail,
  },

  mutations: {
    SET_ACCOUNT_LIST(state, data) {
      state.account_list = data;
    },
    SET_PAGE_TOTAL(state, data) {
      state.pageTotal = data;
    },
    SET_ACCOUNT_DETAIL(state, data) {
      state.accountDetail = data;
      state.is_detail = true;
    },
    SET_IS_DETAIL(state, data) {
      state.is_detail = data;
    },
    CLEAR_ACCOUNT_DETAIL(state) {
      state.accountDetail = null;
    },
    SET_SEARCH_CONDITION(state, data) {
      state.search_condition = data;
    },
    CLEAR_SEARCH(state) {
      state.search_condition = null;
    },
    RESET(state) {
      state.account_list = [];
      state.search_condition = null;
      state.pageTotal = 0;
      state.accountDetail = null;
      state.is_detail = false;
    },
  },

  actions: {
    //取得帳號列表
    async doGetUserList({ getters, commit }, data) {
      const payload = { start: data.start, length: getters.getPageSize, shop_id: [], user_id: '', permission_id: 0 };
      const list = await userList(payload);
      if (data.type === 'init') {
        const sum = await userSummary({ shop_id: [], user_id: '', permission_id: 0 });
        commit('SET_PAGE_TOTAL', sum.total_count);
      }
      commit('SET_ACCOUNT_LIST', list.data);
      return Promise.resolve();
    },
    //進階搜尋帳號
    async doSearchUserList({ getters, commit }, data) {
      let payload = {
        ...data.search,
        start: data.start,
        length: getters.getPageSize,
      };
      payload.shop_id = data.search.shop_id.map((e) => e.split(' ')[0]);
      payload.permission_id = parseInt(data.search.permission_id.split(' ')[0]);
      const list = await userList(payload);
      if (data.type === 'search') {
        const sum = await userSummary(payload);
        commit('SET_PAGE_TOTAL', sum.total_count);
      }
      commit('SET_ACCOUNT_LIST', list.data);
      commit('SET_SEARCH_CONDITION', data.search);
      return Promise.resolve();
    },
  },
};
