import request from '@/utils/request';
const baseURL = '/api/backend/';

//登入
export function userLogin(data) {
  return request({
    method: 'post',
    url: `${baseURL}dayungs/login`,
    data,
  });
}

//登出
export function userLogout(data) {
  return request({
    method: 'get',
    url: `${baseURL}logout`,
    data,
  });
}

//取得店家資訊
export function getStoreInfo() {
  return request({
    method: 'get',
    url: `${baseURL}dayungs/getStoreInfo`,
  });
}
