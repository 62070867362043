import request from '@/utils/request';
const baseURL = '/api/backend/';

//上傳檔案
export function uploadFile(data) {
  return request({
    method: 'post',
    url: `${baseURL}uploadFile`,
    data,
  });
}

//上傳影片
export function uploadVideo(data, onUploadProgress) {
  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 600000,
    loading: false,
    method: 'post',
    url: `${baseURL}uploadFileWithForm`,
    data,
    onUploadProgress,
  });
}

//取得商店列表
export function storeList() {
  return request({
    method: 'get',
    url: `${baseURL}storeList`,
  });
}

//取得商品樹
export function getProductCategoryTree() {
  return request({
    method: 'get',
    url: `${baseURL}getProductCategoryTree`,
  });
}

//取得商品類別
export function getProductCategoryList() {
  return request({
    method: 'get',
    url: `${baseURL}getProductCategoryList`,
  });
}

//取得商品清單
export function getProductInfo() {
  return request({
    method: 'get',
    url: `${baseURL}getProductInfo`,
  });
}

//取得商品選項
export function getProductOptionInfo(product_id) {
  return request({
    method: 'get',
    url: `${baseURL}getProductOptionInfo?product_id=${product_id}`,
  });
}

//取得類別選項
export function getCategoryOptionInfo(category_id) {
  return request({
    method: 'get',
    url: `${baseURL}getCategoryOptionInfo?category_id=${category_id}`,
  });
}

//選項對照表
export function getOptionList() {
  return request({
    method: 'get',
    url: `${baseURL}getOptionList`,
  });
}

//取得權限列表
export function getAccountPermission() {
  return request({
    method: 'get',
    url: `${baseURL}getAccountPermission`,
  });
}

//取得價格模式列表
export function getPriceSet() {
  return request({
    method: 'get',
    url: `${baseURL}getPriceSet`,
  });
}
