import { createRouter, createWebHistory } from 'vue-router';

//-----------------關於總公司與門市的權限控制----------------------//
//此為控制外層submenu的arr，對應name
const headquarters_submenu = ['order', 'discount', 'product', 'system', 'information', 'account'];
const shop_submenu = ['order', 'product', 'system'];
/*
若為內層選單(children)需要區分權限：
> 總公司權限：meta.headquarters = true;
>門市權限：meta.headquarters = false;
> 兩者都可以看到的頁面：不需要寫meta.headquarters
*/
//-------------------------------------------------------------//

const routes = [
  {
    path: '',
    redirect: '/order/list',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: 'POS-會員登入',
    },
  },
  {
    icon: 'clipboard-check',
    submenu: true,
    path: '/order',
    name: 'order',
    meta: {
      name: '訂單管理',
    },
    component: () => import('../views/Index.vue'),
    children: [
      {
        path: 'list',
        name: 'order-list',
        component: () => import('../views/order/ManageOrder.vue'),
        meta: {
          title: 'POS-交易管理',
          name: '交易管理',
        },
        children: [
          {
            path: 'detail',
            name: 'order-detail',
            component: () => import('../views/order/ManageOrder.vue'),
            meta: {
              title: 'POS-訂單詳細內容',
              name: '詳細',
            },
          },
        ],
      },
      {
        path: 'report',
        name: 'order-report',
        component: () => import('../views/order/Report.vue'),
        meta: {
          title: 'POS-銷售報表',
          name: '銷售報表',
        },
        children: [
          {
            path: 'detail',
            name: 'report-detail',
            component: () => import('../views/order/Report.vue'),
            meta: {
              title: 'POS-報表內容',
              name: '報表內容',
            },
          },
        ],
      },
      {
        path: 'analysis',
        name: 'order-analysis',
        component: () => import('../views/order/Analysis.vue'),
        meta: {
          title: 'POS-銷售分析',
          name: '銷售分析',
        },
      },
    ],
  },
  {
    icon: 'percent',
    submenu: true,
    path: '/discount',
    name: 'discount',
    meta: {
      name: '折扣及優惠設定',
    },
    component: () => import('../views/Index.vue'),
    children: [
      {
        path: 'manual',
        name: 'manual-discount',
        component: () => import('../views/discount/ManualDiscount.vue'),
        meta: {
          title: 'POS-手動折扣設定',
          name: '手動折扣設定',
        },
      },
      {
        path: 'auto',
        name: 'auto-discount',
        component: () => import('../views/discount/AutoDiscount.vue'),
        meta: {
          title: 'POS-自動優惠設定',
          name: '自動優惠設定',
        },
      },
      {
        path: 'gift',
        name: 'store-gift',
        component: () => import('../views/discount/StoreGift.vue'),
        meta: {
          title: 'POS-來店禮活動設定',
          name: '來店禮活動設定',
        },
      },
      {
        path: 'record',
        name: 'store-gift-record',
        component: () => import('../views/discount/StoreGiftRecord.vue'),
        meta: {
          title: 'POS-查詢來店禮兌換紀錄',
          name: '查詢來店禮兌換紀錄',
        },
      },
    ],
  },
  {
    icon: 'basket',
    submenu: true,
    path: '/product',
    name: 'product',
    meta: {
      name: '商品管理',
    },
    component: () => import('../views/Index.vue'),
    children: [
      {
        path: 'list',
        name: 'product-list',
        component: () => import('../views/product/SetProduct.vue'),
        meta: {
          title: 'POS-商品設定',
          name: '商品設定',
          headquarters: false, //僅顯示於門市帳號
        },
      },
      {
        path: 'default',
        name: 'product-default',
        component: () => import('../views/product/SetDefault.vue'),
        meta: {
          title: 'POS-商品設定',
          name: '商品設定',
          headquarters: true, //僅顯示於總公司帳號
        },
        children: [
          {
            path: 'detail',
            name: 'default-detail',
            component: () => import('../views/product/SetDefault.vue'),
            meta: {
              title: 'POS-設定內容',
              name: '設定內容',
            },
          },
        ],
      },
      {
        path: 'meal',
        name: 'product-meal',
        component: () => import('../views/product/SetMeal.vue'),
        meta: {
          title: 'POS-套餐設定',
          name: '套餐設定',
          headquarters: true, //僅顯示於總公司帳號
        },
      },
    ],
  },
  {
    icon: 'gear',
    submenu: true,
    path: '/system',
    name: 'system',
    meta: {
      name: '系統設定',
    },
    component: () => import('../views/Index.vue'),
    children: [
      {
        path: 'shop',
        name: 'shop-list',
        component: () => import('../views/system/SetShopPos.vue'),
        meta: {
          title: 'POS-門市系統設定',
          name: '門市系統設定',
        },
        children: [
          {
            path: 'detail',
            name: 'shop-pos-detail',
            component: () => import('../views/system/SetShopPos.vue'),
            meta: {
              title: 'POS-設定內容',
              name: '設定內容',
            },
          },
          {
            path: 'search',
            name: 'shop-pos-search',
            component: () => import('../views/system/SetShopPos.vue'),
            meta: {
              title: 'POS-門市系統條件查詢',
              name: '條件查詢',
              hide: true,
            },
            children: [
              {
                path: 'detail',
                name: 'shop-pos-search-detail',
                component: () => import('../views/system/SetShopPos.vue'),
                meta: {
                  title: 'POS-設定內容',
                  name: '設定內容',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'preferences',
        name: 'preferences',
        component: () => import('../views/system/SetPreferences.vue'),
        meta: {
          title: 'POS-系統偏好設定',
          name: '系統偏好設定',
          headquarters: true, //僅顯示於總公司帳號
        },
      },
      {
        path: 'platform',
        name: 'external-platform',
        component: () => import('../views/system/ExternalPlatform.vue'),
        meta: {
          title: 'POS-外部平台相關設定',
          name: '外部平台相關設定',
          headquarters: false, //僅顯示於門市帳號
        },
      },
    ],
  },
  {
    icon: 'chat-right-text',
    submenu: true,
    path: '/information',
    name: 'information',
    meta: {
      name: '客顯及訊息設定',
    },
    component: () => import('../views/Index.vue'),
    children: [
      {
        path: 'media',
        name: 'media',
        component: () => import('../views/information/SetMedia.vue'),
        meta: {
          title: 'POS-客顯畫面',
          name: '客顯畫面',
        },
      },
      {
        path: 'marquee',
        name: 'marquee',
        component: () => import('../views/information/Marquee.vue'),
        meta: {
          title: 'POS-跑馬燈',
          name: '跑馬燈',
        },
      },
      {
        path: 'notification',
        name: 'notification',
        component: () => import('../views/information/Notification.vue'),
        meta: {
          title: 'POS-訊息通知',
          name: '訊息通知',
        },
      },
      {
        path: 'news',
        name: 'news',
        component: () => import('../views/information/News.vue'),
        meta: {
          title: 'POS-小白單訊息',
          name: '小白單訊息',
        },
      },
    ],
  },
  {
    icon: 'person',
    submenu: true,
    path: '/account',
    name: 'account',
    meta: {
      name: '帳號權限管理',
    },
    component: () => import('../views/Index.vue'),
    children: [
      {
        path: 'list',
        name: 'account-list',
        component: () => import('../views/account/ManageAccount.vue'),
        meta: {
          title: 'POS-帳號管理',
          name: '帳號管理',
        },
        children: [
          {
            path: 'detail',
            name: 'account-detail',
            component: () => import('../views/account/ManageAccount.vue'),
            meta: {
              title: 'POS-帳號內容',
              name: '詳細內容',
            },
          },
          {
            path: 'search',
            name: 'account-search',
            component: () => import('../views/account/ManageAccount.vue'),
            meta: {
              title: 'POS-帳號條件查詢',
              name: '條件查詢',
              hide: true,
            },
            children: [
              {
                path: 'detail',
                name: 'report-search-detail',
                component: () => import('../views/account/ManageAccount.vue'),
                meta: {
                  title: 'POS-帳號內容',
                  name: '詳細內容',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'permission',
        name: 'manage-permission',
        component: () => import('../views/account/ManagePermission.vue'),
        meta: {
          title: 'POS-權限管理',
          name: '權限管理',
        },
        children: [
          {
            path: 'detail',
            name: 'permission-detail',
            component: () => import('../views/account/ManagePermission.vue'),
            meta: {
              title: 'POS-帳號內容',
              name: '詳細內容',
            },
          },
        ],
      },
    ],
  },
];

const DEFAULT_TITLE = 'POS系統後台';
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  const isLogin = sessionStorage.getItem('session');
  if (isLogin) {
    if (to.path === '/login') next('/');
    else next();
  } else {
    if (to.path !== '/login') next('/login');
    else next();
  }
  document.title = to.meta.title || DEFAULT_TITLE;
});

export { routes, headquarters_submenu, shop_submenu };
export default router;
