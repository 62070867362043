import { marqueeList, marqueeSave } from '@/api/mediaMarquee';
export default {
  namespaced: true,
  state: {
    marquee_list: [],
    pageSize: 10,
  },

  getters: {
    getMarqueeList: (state) => state.marquee_list,
    getPageSize: (state) => state.pageSize,
  },

  mutations: {
    SET_MARQUEE_LIST(state, data) {
      state.marquee_list = data;
    },
    RESET(state) {
      state.marquee_list = [];
    },
  },
  actions: {
    //取得跑馬燈清單
    async doGetMarqueeList({ commit }) {
      const list = await marqueeList();
      commit('SET_MARQUEE_LIST', list.data);
      return Promise.resolve();
    },
    //修改跑馬燈
    async doSaveMarquee(context, data) {
      await marqueeSave(data);
      return Promise.resolve();
    },
  },
};
